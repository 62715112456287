* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.App {
    background-color: #DDF4F2;
    width: 100%;
    height: 100vh;
}
